import { del, get, patch, post, put } from "services/ApiRequests";
import ApiConstants from "services/constants/ApiConstants";
import { BankAccountStatus } from "services/constants/Enums";
import { getReadableDay } from "utils/common";

const getCalls = async (serviceType, type, page = 1): Promise<any> => {
  const params =
    type === "issue"
      ? { issue: true }
      : type === "completed"
        ? { past: true }
        : { upcoming: true };
  params["page"] = page;
  params["type"] = serviceType;
  const API_URL = `${ApiConstants.GET_CALLS_DATA}`;
  const apiResponse = await get(API_URL, params, { viaOauth: true });
  const results = apiResponse?.data?.results.map((object) => {
    return { ...object, day: getReadableDay(object?.time) };
  });
  return { ...apiResponse?.data, results: results };
};

const getPackageCalls = async (type, page = 1): Promise<any> => {
  const params =
    type === "issue"
      ? { issue: true }
      : type === "completed"
        ? { past: true }
        : { upcoming: true };
  params["page"] = page;

  const API_URL = `${ApiConstants.GET_PACKAGE_CALLS_DATA}`;
  const apiResponse = await get(API_URL, params, { viaOauth: true });
  return apiResponse;
};

const getProductBookings = async (): Promise<any> => {
  const API_URL = `${ApiConstants.GET_PRODUCT_BOOKING_DATA}`;
  const apiResponse = await get(API_URL, {}, { viaOauth: true });
  return apiResponse;
};

const getReferral = async (): Promise<any> => {
  const apiResponse = await get(
    ApiConstants.GET_REFERRAL,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getReferralDetails = async (postobj): Promise<any> => {
  const apiResponse = await post(ApiConstants.GET_REFERRAL, postobj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

const getTestimonial = async (id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_TESTIMONIAL}${id}/`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getAnalytics = async (time_range): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_ANALYTICS}?time_range=${time_range}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getDashboardStats = async (time_range): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_DASHBAORD_STATS}?interval=${time_range}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const shareOnLinkedin = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.POST_TESTIMONIAL}`, postObj, {
    viaOauth: true,
  });
  return apiResponse;
};

const getProfile = async (): Promise<any> => {
  const apiResponse = await get(
    ApiConstants.GET_PROFILE,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const updateProfile = async (postObj): Promise<any> => {
  const apiResponse = await patch(`${ApiConstants.UPDATE_PROFILE}`, postObj, {
    viaOauth: true,
  });
  return apiResponse;
};

const updatePassword = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.UPDATE_PASSWORD}`, postObj, {
    viaOauth: true,
  });
  return apiResponse;
};

const updateMilestone = async (
  templateId,
  update,
  postObj = {}
): Promise<any> => {
  const apiResponse = await patch(
    `${ApiConstants.UPDATE_MILESTONE}${templateId}?action=${update}`,
    postObj,
    { viaOauth: true }
  );
  return apiResponse;
};

const addSlots = async (postObj): Promise<any> => {
  const apiResponse = await put(`${ApiConstants.ADD_SLOTS}`, postObj, {
    viaOauth: true,
  });
  return apiResponse;
};

const getSampleServices = async (type): Promise<any> => {
  const apiResponse = await get(
    type
      ? `${ApiConstants.GET_SAMPLE_SERVICES}?type=${type}`
      : `${ApiConstants.GET_SAMPLE_SERVICES}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getNudgeInfo = async (nudge_type, entity_id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_NUDGE}?nudge_type=${nudge_type}&entity_id=${entity_id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const addOnboardingServices = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.ADD_SAMPLE_SERVICES}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse;
};

const rescheduleBooking = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.RESCHEDULE_BOOKING}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse;
};

const checkUserNameExists = async (username): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.CHECK_USERNAME}${username}`,
    {}
  );
  return apiResponse;
};

const getPublicServices = async (id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_SERVICES}${id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getRescheduleServices = async (id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_RESCHEDULE_SERVICES}${id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getUserByUsername = async (username): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_BY_USERNAME}?username=${username}`,
    {}
  );
  return apiResponse?.data;
};

const getUserByUsernameAdditional = async (username): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_BY_USERNAME_ADDITIONAL}?username=${username}`,
    {}
  );
  return apiResponse?.data;
};

const getUserByUsernameLite = async (username): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_BY_USERNAMELITE}?username=${username}`,
    {}
  );
  return apiResponse?.data;
};

const getRescheduleBookingSlots = async (booking_id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.RESCHEDULE_BOOKING_SLOTS}${booking_id}`,
    {}
  );
  return apiResponse;
};

const getBookingDetails = async (booking_id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_BOOKING_DATA}${booking_id}`,
    {}
  );
  return apiResponse?.data;
};

const getBookingStatus = async (booking_id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_BOOKING_STATUS}${booking_id}`,
    {}
  );
  return apiResponse?.data;
};

const getAvailableSlots = async (
  service_id,
  user_id,
  interval
): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_AVAILABLE_SLOTS}?user_id=${user_id}&service_id=${service_id}&interval=${interval}`,
    {}
  );
  return apiResponse?.data;
};

const getUserRewards = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_USER_REWARDS}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const claimReward = async (id: string | number, postObj = {}): Promise<any> => {
  const apiResponse = await patch(
    `${ApiConstants.CLAIM_REWARD}${id}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const applyCoupon = async (postObj: any = {}): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.APPLY_COUPON}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

const rescheduleBookingFollower = async (booking_id, postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.RESCHEDULE_BOOKING_SLOTS}${booking_id}`,
    postObj,
    {
      viaOauth: false,
    }
  );
  return apiResponse;
};

const requestSlot = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.REQUEST_A_SLOT}`, postObj, {
    viaOauth: false,
  });
  return apiResponse;
};

const getUserStats = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_STATS}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getLinkedinAnalytics = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_POST_ANALYTICS}`,
    {},
    { viaOauth: true }
  );
  return apiResponse;
};

const getExpertCategories = async (): Promise<any> => {
  const apiResponse = await get(`${ApiConstants.GET_EXPERT_CATEGORIES}`, {});
  return apiResponse?.data;
};

const getPackagedWebinar = async (): Promise<any> => {
  const apiResponse = await get(`${ApiConstants.GET_PACKAGE_WEBINAR}`, {});
  return apiResponse?.data;
};

const getExpertise = async (): Promise<any> => {
  const apiResponse = await get(`${ApiConstants.GET_EXPERTISE}`, {});
  return apiResponse?.data;
};

const getFeaturedUsers = async (): Promise<any> => {
  const apiResponse = await get(ApiConstants.GET_FEATURED_USERS);
  return apiResponse?.data;
};

const getBankAccountStatus = async (): Promise<BankAccountStatus> => {
  const response = await get(
    ApiConstants.BANK_ACCOUNT_STATUS,
    {},
    { viaOauth: true }
  );
  return response.data.status;
};

const getBankAccountDetails = async (): Promise<void> => {
  const response = await get(
    ApiConstants.BANK_ACCOUNT_DETAILS,
    {},
    { viaOauth: true }
  );
  return response?.data;
};

const addStripeAccount = async (): Promise<string> => {
  const response = await post(
    ApiConstants.ADD_STRIPE_ACCOUNT,
    {},
    { viaOauth: true }
  );
  return response.data.url;
};

const removeStripeAccount = async (): Promise<any> => {
  const response = await del(
    ApiConstants.ADD_STRIPE_ACCOUNT,
    {},
    { viaOauth: true }
  );
  return response;
};

const getNextSteps = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_STEPS}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const updateNextSteps = async (postObj): Promise<any> => {
  const apiResponse = await patch(
    `${ApiConstants.UPDATE_ONBOARDING_ACTIONS}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const getBookingInfo = async (bookingID): Promise<any> => {
  const APIURL = ApiConstants.GET_BOOKING_DETAILS + bookingID;
  const apiResponse = await get(APIURL, {}, { viaOauth: false });
  return apiResponse?.data;
};

const getVideoKeys = async (bookingID): Promise<any> => {
  const APIURL = ApiConstants.GET_VIDEO_KEYS;
  const apiResponse = await get(
    APIURL,
    {
      info_id: bookingID,
    },
    { viaOauth: false }
  );
  return apiResponse?.data;
};

const getShareTemplates = async (token = null): Promise<any> => {
  const params = {
    viaOauth: true,
  };
  if (token) {
    params["token"] = token;
  }
  const apiResponse = await get(
    `${ApiConstants.GET_SHARE_TEMPLATES}`,
    {},
    params
  );
  return apiResponse?.data;
};

const getShareTemplatesV2 = async (section): Promise<any> => {
  const params = {
    viaOauth: true,
  };
  const apiResponse = await get(
    `${ApiConstants.GET_SHARE_TEMPLATES_V2}?section=${section}`,
    {},
    params
  );
  return apiResponse?.data;
};

const getCustomPageData = async (pageName): Promise<any> => {
  const apiResponse = await get(ApiConstants.CUSTOM_PAGE_DATA, {
    page: pageName,
  });
  return apiResponse?.data;
};

const getAIPost = async (templateId): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.AI_POST_CONTENT}${templateId}/`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getSchedule = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_SCHEDULES}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getSharingMeta = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_SHARING_META}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const postSchedule = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.GET_SCHEDULES}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

const patchSchedule = async (id, postObj): Promise<any> => {
  const apiResponse = await patch(
    `${ApiConstants.GET_SCHEDULE}${id}`,
    postObj,
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const delSchedule = async (id): Promise<any> => {
  const apiResponse = await del(
    `${ApiConstants.GET_SCHEDULE}${id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getSlotsList = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_SLOTLIST}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getBlockedDates = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_BLOCKED_DATES}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getCalendarList = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_CALENDAR_LIST}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const changePrimaryCalendar = async (id, data): Promise<any> => {
  const apiResponse = await put(
    `${ApiConstants.GET_CALENDAR_LIST}${id}/`,
    data,
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const deleteCalendar = async (id): Promise<any> => {
  const apiResponse = await del(
    `${ApiConstants.GET_CALENDAR_LIST}${id}/`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const postBlockedDates = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.GET_BLOCKED_DATE}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

const delBlockedDates = async (id): Promise<any> => {
  const apiResponse = await del(
    `${ApiConstants.GET_BLOCKED_DATE}${id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getAccountingDetails = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_ACCOUNTING_DETAILS}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getCanWithdrawMoney = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_CAN_WITHDRAW_MONEY}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const reportIssue = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.REPORT_ISSUE}`, postObj, {
    viaOauth: false,
  });
  return apiResponse;
};

const getFreeLink = async (booking_id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_FREE_LINK}${booking_id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse;
};

const getWebinarRegistration = async (service_id, page): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_WEBINAR_REGISTRATIONS}${service_id}?page=${page}`,
    {},
    { viaOauth: true }
  );
  return apiResponse;
};

const getDocRegistration = async (service_id, page): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_DOC_REGISTRATIONS}${service_id}?page=${page}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getRegistrationList = async (service_id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_REGISTRATIONS_LIST}${service_id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse;
};

const getDocBuyerDownload = async (service_id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_DOC_BUYERS_DOWNLOAD}${service_id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getProductTestimonials = async (service_id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_PRODUCT_TESTIMONIALS}${service_id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const sendEmail = async (emailObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.SEND_EMAIL}`, emailObj, {
    viaOauth: true,
  });
  return apiResponse;
};

const sendPackageEmail = async (emailObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.SEND_PACKAGE_EMAIL}`,
    emailObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse;
};

const sendProductEmail = async (emailObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.SEND_PRODUCT_EMAIL}${emailObj?.service_id}`,
    emailObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse;
};

const getSharingInfo = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.SHARING_DATA}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const updateLinkedinData = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.UPDATE_FROM_LINKEDIN}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const getDynamicPrice = async (charge): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.DYNAMIC_PRICE}?amount=${charge}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const askCallTestimonial = async (bookingId): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.ASK_TESTIMONIAL}${bookingId}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const askWebinarTestimonial = async (bookingId): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.ASK_TESTIMONIAL_WEBINAR}${bookingId}/`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const getDashboardServices = async (type, page = 1): Promise<any> => {
  const APIURL =
    ApiConstants.GET_DASHBOARD_SERVICES + `?type=${type}&page=${page}`;
  const apiResponse = await get(APIURL, {}, { viaOauth: true });
  return apiResponse?.data;
};

const getServiceDetails = async (serviceID, params = ""): Promise<any> => {
  const APIURL = ApiConstants.GET_SERVICE_DETAILS + serviceID + params;
  const apiResponse = await get(APIURL, {}, { viaOauth: true });
  return apiResponse?.data;
};

const updateService = async (postObj): Promise<any> => {
  const APIURL = ApiConstants.GET_SERVICE_DETAILS + postObj?.id;
  const apiResponse = await patch(APIURL, postObj, {
    viaOauth: true,
  });
  return apiResponse;
};

const postDiscountCode = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.DISCOUNT_CODE}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

const validateCoupon = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.VALIDATE_CODE}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

const deleteDiscountCode = async (id): Promise<any> => {
  const apiResponse = await del(
    `${ApiConstants.DISCOUNT_CODE}${id}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const updateDiscountCode = async (postObj): Promise<any> => {
  const apiResponse = await patch(
    `${ApiConstants.DISCOUNT_CODE}${postObj?.id}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const markBookingComplete = async (booking_id): Promise<any> => {
  const apiResponse = await patch(
    `${ApiConstants.RESCHEDULE_BOOKING_COMPLETED}${booking_id}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const getServicesOrder = async (): Promise<any> => {
  const APIURL = ApiConstants.GET_SERVICES_ORDER;
  const apiResponse = await get(APIURL, {}, { viaOauth: true });
  return apiResponse?.data;
};

const postServicesOrder = async (obj): Promise<any> => {
  const APIURL = ApiConstants.GET_SERVICES_ORDER;
  const apiResponse = await post(APIURL, obj, { viaOauth: true });
  return apiResponse?.data;
};

const getUserReferral = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_USER_REFERRALS}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getAIPosts = async (): Promise<any> => {
  const APIURL = ApiConstants.GET_AI_POSTS;
  const apiResponse = await get(APIURL, {}, { viaOauth: true });
  return apiResponse?.data;
};

const getSSOToken = async (): Promise<any> => {
  const APIURL = ApiConstants.GET_SSO_Token;
  const apiResponse = await get(APIURL, {}, { viaOauth: true });
  return apiResponse?.data;
};

const createCallExperience = async (postObj): Promise<any> => {
  const APIURL = ApiConstants.CREATE_CALL_EXPERIENCE;
  const apiResponse = await post(APIURL, postObj);
  return apiResponse?.data;
};
/**
 * Fetches a list of sharing templates from the server.
 * @returns A Promise that resolves with the list of templates or rejects with an error.
 */
const getShareTempaltesListing = async (): Promise<any> => {
  const APIURL = ApiConstants.GET_SHARING_TEMPLATES_V2;
  const apiResponse = await get(APIURL, {}, { viaOauth: true });
  return apiResponse?.data;
};

/**
 * Retrieves share category templates for a given category.
 *
 * @param {string} category - The category to retrieve templates for.
 * @returns {Promise<any>} - A Promise that resolves with the retrieved data or rejects with an error.
 */
const getShareCategoryTemplates = async (category): Promise<any> => {
  const APIURL = ApiConstants.GET_SHARING_CATEGORY + category;
  const apiResponse = await get(APIURL, {}, { viaOauth: true });
  return apiResponse?.data;
};

/**
 * Retrieve details of a sharing template by its ID.
 *
 * @async
 * @param id - The ID of the sharing template to retrieve.
 * @returns A Promise resolving to the details of the sharing template.
 */
const getShareTemplateDetails = async (id): Promise<any> => {
  const APIURL = ApiConstants.GET_SHARING_TEMPLATE_DETAILS + id;
  try {
    const apiResponse = await get(APIURL, {}, { viaOauth: true });
    return apiResponse?.data;
  } catch (error) {
    return error;
  }
};

const shareOnLinkedinV2 = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.SHARE_ON_LINKEDIN}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

const getCallInfo = async (bookingId, user_type = "follower"): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_BOOKING_INFO}${bookingId}?user_type=${user_type}`,
    {},
    { viaOauth: true }
  );
  return apiResponse;
};

const getQueryInfo = async (bookingId): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_QUERY_INFO}${bookingId}`,
    {},
    { viaOauth: true }
  );
  return apiResponse;
};

const getDocInfo = async (bookingId): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_DOC_INFO}${bookingId}`,
    {},
    { viaOauth: true }
  );
  return apiResponse;
};

const sendTransactionEmail = async (emailObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.TRANSACTION_DETAILS}`,
    emailObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const getTransactionDetails = async (
  type,
  service: any = null,
  filter: any = "",
  page: any = 1
): Promise<any> => {
  const param4 = `?page=${page}`;
  const param1 = type !== "all" ? `&type=${type}` : "";
  const param2 = service !== null ? `&service_id=${service}` : "";
  const param3 =
    filter !== ""
      ? filter < 13
        ? `&months=${filter}`
        : `&service_ids=${filter}`
      : "";
  const params = param4 + param1 + param2 + param3;

  const apiResponse = await get(
    `${ApiConstants.TRANSACTION_DETAILS}${params}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};
const requestReschedule = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.FOLLOWER_REQUEST_RESCHEDULE}`,
    postObj,
    {}
  );
  return apiResponse?.data;
};

const rescheduleCallFollower = async (bookingId, reason): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.RESCHEDULE_CALL_FOLLOWER}${bookingId}`,
    { reason: reason },
    {}
  );
  return apiResponse?.data;
};

const uploadDocService = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.SERVICE_DOC_UPLOADS}`,
    postObj,
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const deleteUploadedDoc = async (id): Promise<any> => {
  const apiResponse = await del(
    `${ApiConstants.SERVICE_DOC_UPLOADS}${id}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getTopEarners = async (type): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.TOP_EARNERS}?leaderboard_type=${type}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getAIControlsParams = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_AI_POST_PARAMS}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const generateAIPost = async (id, postObj = {}): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.GENERATE_AI_POST}${id}`,
    postObj,
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const getForYouTemplates = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_FOR_YOU}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

const cancelWebinar = async (postObj): Promise<any> => {
  const APIURL = ApiConstants.CANCEL_WEBINAR + postObj?.id;
  const apiResponse = await patch(APIURL, postObj, {
    viaOauth: true,
  });
  return apiResponse;
};

const pledgeDonation = async (postObj): Promise<any> => {
  const APIURL = ApiConstants.PLEDGE_DONATION;
  const apiResponse = await patch(APIURL, postObj, {
    viaOauth: true,
  });
  return apiResponse;
};

const getPledgeDonation = async (): Promise<any> => {
  const APIURL = ApiConstants.PLEDGE_DONATION;
  const apiResponse = await get(
    APIURL,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const getCrossSellServices = async (serviceId): Promise<any> => {
  const APIURL = `${ApiConstants.GET_CROSS_SELL_SERVICES}?service=${serviceId}`;
  const apiResponse = await get(
    APIURL,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const getWebinarTestimonials = async (serviceID, page = 1): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_WEBINAR_TESTIMONIALS}${serviceID}?page=${page}&page_size=10`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

export const getSampleEmailCampaigns = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_SAMPLE_EMAIL_CAMPAIGNS}`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

export const getSampleWhatsappCampaigns = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_SAMPLE_EMAIL_CAMPAIGNS}?channel=whatsapp`,
    {},
    { viaOauth: true }
  );
  return apiResponse?.data;
};

export const createCampaigns = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.CAMPAIGNS}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const getCampaignDetails = async (id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.CAMPAIGNS}${id}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

export const updateMarketingCampaigns = async (id, postObj): Promise<any> => {
  const apiResponse = await put(`${ApiConstants.CAMPAIGNS}${id}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const getEmailCampaigns = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.CAMPAIGNS}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

export const getWhatsappCampaigns = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.CAMPAIGNS}?channel=whatsapp`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

export const getCampaignContactList = async (
  channel = "email"
): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.CONTACTS_LIST}?channel=${channel}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

export const addContactList = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.CONTACTS_LIST}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const sendTestCampaign = async (id): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.SEND_TEST_CAMPAIGN}${id}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const updateAutoResponder = async (postObj): Promise<any> => {
  const apiResponse = await patch(
    `${ApiConstants.UPDATE_AUTO_RESPONDER}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

const getInstagramOauth = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.GET_INSTAGRAM_OAUTH}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};
const addCredit = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.CREDIT_TOPUP}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const buyCredits = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.BUY_CREDITS}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

const sendServiceTestimonials = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.SERVICE_SHOWCASE_TESTIMONIALS}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};
export const sendStoryCampaign = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.SEND_STORY}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const getJoinCallLink = async (id): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_JOIN_CALL_LINK}${id}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

export const sendDeleteAccount = async (postObj): Promise<any> => {
  const apiResponse = await del(`${ApiConstants.DELETE_ACCOUNT}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export {
  addCredit,
  addOnboardingServices,
  addSlots,
  addStripeAccount,
  applyCoupon,
  askCallTestimonial,
  askWebinarTestimonial,
  cancelWebinar,
  changePrimaryCalendar,
  checkUserNameExists,
  claimReward,
  createCallExperience,
  delBlockedDates,
  delSchedule,
  deleteCalendar,
  deleteDiscountCode,
  deleteUploadedDoc,
  generateAIPost,
  getAIControlsParams,
  getAIPost,
  getAIPosts,
  getAccountingDetails,
  getAnalytics,
  getAvailableSlots,
  getBankAccountDetails,
  getBankAccountStatus,
  getBlockedDates,
  getBookingDetails,
  getBookingInfo,
  getBookingStatus,
  getCalendarList,
  getCallInfo,
  getCalls,
  getCanWithdrawMoney,
  getCrossSellServices,
  getCustomPageData,
  getDashboardServices,
  getDashboardStats,
  getDocBuyerDownload,
  getDocInfo,
  getDocRegistration,
  getDynamicPrice,
  getExpertCategories,
  getExpertise,
  getFeaturedUsers,
  getForYouTemplates,
  getFreeLink,
  getInstagramOauth,
  getLinkedinAnalytics,
  getNextSteps,
  getNudgeInfo,
  getPackageCalls,
  getPackagedWebinar,
  getPledgeDonation,
  getProductBookings,
  getProductTestimonials,
  getProfile,
  getPublicServices,
  getQueryInfo,
  getReferral,
  getReferralDetails,
  getRegistrationList,
  getRescheduleBookingSlots,
  getRescheduleServices,
  getSSOToken,
  getSampleServices,
  getSchedule,
  getServiceDetails,
  getServicesOrder,
  getShareCategoryTemplates,
  getShareTempaltesListing,
  getShareTemplateDetails,
  getShareTemplates,
  getShareTemplatesV2,
  getSharingInfo,
  getSharingMeta,
  getSlotsList,
  getTestimonial,
  getTopEarners,
  getTransactionDetails,
  getUserByUsername,
  getUserByUsernameAdditional,
  getUserByUsernameLite,
  getUserReferral,
  getUserRewards,
  getUserStats,
  getVideoKeys,
  getWebinarRegistration,
  getWebinarTestimonials,
  markBookingComplete,
  patchSchedule,
  pledgeDonation,
  postBlockedDates,
  postDiscountCode,
  postSchedule,
  postServicesOrder,
  removeStripeAccount,
  reportIssue,
  requestReschedule,
  requestSlot,
  rescheduleBooking,
  rescheduleBookingFollower,
  rescheduleCallFollower,
  sendEmail,
  sendPackageEmail,
  sendProductEmail,
  sendServiceTestimonials,
  sendTransactionEmail,
  shareOnLinkedin,
  shareOnLinkedinV2,
  updateAutoResponder,
  updateDiscountCode,
  updateLinkedinData,
  updateMilestone,
  updateNextSteps,
  updatePassword,
  updateProfile,
  updateService,
  uploadDocService,
  validateCoupon,
};
