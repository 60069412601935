import axios from "axios";
import { get, patch, post, put } from "services/ApiRequests";
import ApiConstants from "services/constants/ApiConstants";
import config from "utils/config";

export const getBooking = async (bookingID): Promise<any> => {
  const APIURL = config.API_URL + ApiConstants.GET_BOOKING_DATA + bookingID;
  const apiResponse = await get(APIURL);
  return apiResponse;
};

export const getPackageBooking = async (bookingID): Promise<any> => {
  const APIURL =
    config.API_URL + ApiConstants.GET_PACKAGE_BOOKING_DATA + bookingID;
  const apiResponse = await get(APIURL);
  return apiResponse;
};

export const cancelMeetingFollower = async (
  bookingID,
  reason
): Promise<any> => {
  const APIURL =
    config.API_URL +
    ApiConstants.CANCEL_MEETING_FOLLOWER +
    bookingID +
    `?reason=${reason}`;
  const apiResponse = await patch(APIURL);
  return apiResponse;
};

export const createBooking = async (
  postObj = {},
  config: any = {}
): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.CREATE_BOOKING}`,
    postObj,
    config
  );
  return apiResponse?.data;
};

export const createPackageBooking = async (
  postObj = {},
  config: any = {}
): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.CREATE_PACKAGE_BOOKING}`,
    postObj,
    config
  );
  return apiResponse?.data;
};

export const apiSocialAuth = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.SOCIAL_AUTH}`, postObj);
  return apiResponse?.data;
};

export const getLinkedinPreview = async (postObj): Promise<any> => {
  const APIURL = config.API_URL + ApiConstants.GET_LINKEDIN_PROFILE;
  const apiResponse = await post(APIURL, postObj);
  return apiResponse;
};

export const getSimilarProfiles = async (expertiseID): Promise<any> => {
  const APIURL =
    config.API_URL + ApiConstants.GET_SIMILAR_PROFILES + expertiseID;
  const apiResponse = await get(APIURL);
  return apiResponse;
};

export const topFollowers = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.TOP_FOLLOWERS}`, postObj);
  return apiResponse?.data;
};

export const getUseCaseCategories = async (id = 0): Promise<any> => {
  let url = config.API_URL + ApiConstants.GET_USE_CASE_CATEGORIES;
  if (id !== 0) {
    url = config.API_URL + ApiConstants.GET_USE_CASE_CATEGORIES + id;
  }
  const APIURL = url;
  const apiResponse = await get(APIURL);
  return apiResponse?.data;
};

export const getCountry = async (): Promise<any> => {
  const APIURL = config.API_URL + ApiConstants.GET_COUNTRY;
  const apiResponse = await get(APIURL);
  return apiResponse?.data?.country;
};

export const subscribe = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.SUBSCRIBE}`, postObj);
  return apiResponse?.data;
};

export const getQueryStatus = async (id): Promise<any> => {
  const apiResponse = await get(`${ApiConstants.GET_QUERY_INFO}${id}`);
  return apiResponse?.data;
};

export const getPublicTestimonials = async (param): Promise<any> => {
  const apiResponse = await get(`${ApiConstants.GET_TESTIMONIALS}${param}`);
  return apiResponse?.data;
};

export const updateTestimonial = async (id, postObj = {}) => {
  const APIURL = `${ApiConstants.GET_TESTIMONIAL}${id}/`;
  const apiResponse = await patch(APIURL, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const revalidateCache = async (path): Promise<any> => {
  const params = new URLSearchParams([
    ["secret", `topmate@123456`],
    ["path", `/${path}`],
  ]);
  const API_URL = `/${ApiConstants.REVALIDATE_CACHE}`;
  const response = await axios.get(API_URL, { params });
  return response?.data;
};

export const billboardPostLink = async (postObj = {}): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.POST_BILLBOARD}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const getUpcomingBillboard = async (
  type,
  page = 1,
  currency = 1
): Promise<any> => {
  const params =
    type === "Upcoming" ? { status: "upcoming" } : { status: "previous" };
  params["page"] = page;
  params["type"] = currency;
  const API_URL = ApiConstants.POST_BILLBOARD;
  const apiResponse = await get(API_URL, params);
  return apiResponse?.data;
};

export const testimonialsReorder = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.TESTIMONIAL_REORDER}`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

export const getExpertsFromQuery = async (query, source): Promise<any> => {
  const params = source ? `&source=${source}` : "";
  const apiResponse = await get(
    `${ApiConstants.GET_EXPERT_FROM_QUERY}${query}${params}`
  );
  return apiResponse?.data;
};

export const getYouWeCanDonors = async (activePage): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_DONORS}?page=${activePage}`
  );
  return apiResponse?.data;
};

export const getUserStories = async (activePage): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_USER_STORIES}?page=${activePage}`
  );
  return apiResponse?.data;
};

export const getThanksGivingMessages = async (user_id, type): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.THANKSGIVING_MESSAGES}?user_id=${user_id}&message_type=${type}`
  );
  return apiResponse?.data;
};

export const addThanksGivingMessage = async (postObj): Promise<any> => {
  const apiResponse = await post(
    `${ApiConstants.THANKSGIVING_MESSAGES}`,
    postObj
  );
  return apiResponse?.data;
};

export const setThanksGivingMessage = async (id, postObj): Promise<any> => {
  const apiResponse = await put(
    `${ApiConstants.THANKSGIVING_MESSAGES_UPDATE}${id}/`,
    postObj,
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

export const createSupportMyWork = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.SUPPORT_MY_WORK}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const joinAIWaitlistAPI = async (postObj): Promise<any> => {
  const apiResponse = await post(`${ApiConstants.AI_WAIT_LIST}`, postObj, {
    viaOauth: true,
  });
  return apiResponse?.data;
};

export const getSmartDiscountCodes = async (
  id,
  type = "service"
): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.GET_SMART_DISCOUNT_CODE}?${type}=${id}`
  );
  return apiResponse?.data;
};

export const downloadChristmasMessages = async (): Promise<any> => {
  const apiResponse = await get(
    `${ApiConstants.DOWNLOAD_CHRISTMAS_MESSAGES}`,
    {},
    {
      viaOauth: true,
    }
  );
  return apiResponse?.data;
};

export const paymentPayglocal = async (postObj, query): Promise<any> => {
  var val = `?`;
  const values = Object.entries(query);
  values.map((item) => {
    val = val + `${item[0]}=${item[1]}&`;
  });

  const apiResponse = await post(
    `${ApiConstants.PAYMENTS_PAYGLOCAL}${val}`,
    postObj
  );

  return apiResponse?.data;
};
